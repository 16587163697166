<template>
  <div class="login-view">
    <Login/>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Login from '@/components/authorization/Login.vue'

  export default {
    name: 'login',
    components: {
      Login
    }
  }
</script>

<style scoped>
  .login-view {
    width: 21rem;
    height: 20rem;
    display: inline-block;
    position: fixed;
    left: 50%;
    top: 45%;
    margin-left: -10.5rem;
    margin-top: -12rem;
    z-index: 5;
  }
</style>